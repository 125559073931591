import { ProtocolMode } from '@azure/msal-browser'

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const tenantName = process.env.REACT_APP_TENANT_NAME
const customPolicyName = process.env.REACT_APP_CUSTOM_POLICY_NAME

export default {
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${customPolicyName}`,
  knownAuthorities: [`${tenantName}.b2clogin.com`],
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  protocolMode: ProtocolMode.AAD,
}
