import React, { FC } from 'react'
import { Modal } from '@sympaoy/pomegranate'

interface Props {
  children: React.ReactNode
  dataTestId: string
  isOpen: boolean
  onConfirm: () => void
  onReject: () => void
  title: string
}

export const Confirm: FC<Props> = ({
  children,
  dataTestId,
  isOpen,
  onConfirm,
  onReject,
  title,
}) => {
  return (
    <Modal
      qaId={dataTestId}
      title={title}
      isOpen={isOpen}
      setIsOpen={onReject}
      primaryButtonProps={{
        children: 'Confirm',
        qaId: `${dataTestId}__confirm-button`,
        onPress: onConfirm,
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        qaId: `${dataTestId}__reject-button`,
        onPress: onReject,
      }}
      showButtons
    >
      {children}
    </Modal>
  )
}
