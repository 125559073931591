import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import scopes from './scopes'

function useAccessToken() {
  const { instance } = useMsal()

  return {
    getAccessToken: async () => {
      const activeAccount = instance.getActiveAccount()
      if (activeAccount) {
        const accessTokenRequest = {
          scopes,
          account: activeAccount,
        }

        try {
          const token = await instance.acquireTokenSilent(accessTokenRequest)
          return token.accessToken
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            const response = await instance.acquireTokenPopup(
              accessTokenRequest
            )
            return response.accessToken
          }
        }
      }

      return null
    },
  }
}

export default useAccessToken
