import React, { useMemo, useState } from 'react'
import { Card, Switch } from '@sympaoy/pomegranate'
import { OrganizationModel } from '@api/models.ts'
import { Confirm } from '@components/Confirm'

interface Props {
  organizationData: OrganizationModel
  onNewRolesEnabled: (value: boolean, addUsersToDefaultRole: boolean) => void
  qaIdPrefix: string
}

export const OrganizationSettingsCard: React.FC<Props> = ({
  organizationData,
  onNewRolesEnabled,
  qaIdPrefix,
}) => {
  const newRolesSwitchBlocked = useMemo(
    () => organizationData?.organizationSettings?.isNewRolesEnabled ?? true,
    [organizationData]
  )
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [addUsersToDefaultRole, setAddUsersToDefaultRole] = useState(false)

  return (
    <>
      <Card title="Settings" qaId={`${qaIdPrefix}__settings-card`}>
        <Switch
          qaId="settings_card_new-roles-enabled_switch"
          isSelected={organizationData.organizationSettings.isNewRolesEnabled}
          disabled={newRolesSwitchBlocked}
          onChange={() => setConfirmVisible(true)}
        >
          New Roles Feature
        </Switch>
      </Card>
      <Confirm
        isOpen={confirmVisible}
        onConfirm={() => {
          onNewRolesEnabled(true, addUsersToDefaultRole)
          setConfirmVisible(false)
          setAddUsersToDefaultRole(false)
        }}
        dataTestId="new-roles-enabled_confirm"
        onReject={() => setConfirmVisible(false)}
        title="Enable New Roles Feature"
      >
        <div style={{ marginBottom: '16px' }}>
          This operation CANNOT BE REVERTED!
        </div>
        <Switch
          qaId="confirm_add-default-role_switch"
          isSelected={addUsersToDefaultRole}
          onChange={() => setAddUsersToDefaultRole(!addUsersToDefaultRole)}
        >
          Assign all users from organization to default role
        </Switch>
      </Confirm>
    </>
  )
}
