import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_ORGANIZATIONS } from '../api/queries'
import OrganizationsTable from '../components/organization/OrganizationsTable'

const Organizations = () => {
  const DEFAULT_QUERY_VARIABLES = {
    first: OrganizationsTable.defaultProps.rowsPerPage,
  }

  const [page, setPage] = React.useState(OrganizationsTable.defaultProps.page)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    OrganizationsTable.defaultProps.rowsPerPage
  )
  const [queryVariables, setQueryVariables] = React.useState(
    DEFAULT_QUERY_VARIABLES
  )

  const { data, loading } = useQuery(GET_ORGANIZATIONS, {
    variables: queryVariables,
  })

  const handleChangePage = (newPage) => {
    setPage(newPage)
    setQueryVariables(
      newPage > page
        ? {
            first: rowsPerPage,
            after: data.organizations.pageInfo.endCursor,
          }
        : {
            last: rowsPerPage,
            before: data.organizations.pageInfo.startCursor,
          }
    )
  }

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
    setPage(OrganizationsTable.defaultProps.page)
    setQueryVariables({ first: newRowsPerPage })
  }

  return (
    !loading &&
    data && (
      <OrganizationsTable
        organizations={data.organizations.nodes}
        totalCount={data.organizations.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    )
  )
}

export default Organizations
