import React from 'react'
import { Link } from 'react-router-dom'
import {
  ButtonIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@sympaoy/pomegranate'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import PropTypes from 'prop-types'

const OrganizationsTable = ({
  organizations,
  totalCount,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handleOnPageChange = (event, newPage) => {
    onPageChange(newPage)
  }

  const handleOnRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    onRowsPerPageChange(newRowsPerPage)
  }

  return (
    organizations.length > 0 && (
      <Table id="organizations__table" qaId="organizations__table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Directory</TableCell>
            <TableCell>Name</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map((organization) => (
            <TableRow key={organization.id}>
              <TableCell>{organization.id}</TableCell>
              <TableCell>{organization.directory}</TableCell>
              <TableCell>{organization.name}</TableCell>
              <TableCell>
                <Link to={`../organizations/${organization.id}`}>
                  <ButtonIcon
                    qaIdIcon={`organizations__table__${organization.code}__azure__icon`}
                    icon="edit-solid"
                    qaIdHoverBg={`organizations__table__${organization.code}__azure__button`}
                    ariaLabel="Edit Configuration"
                  />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={4}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleOnPageChange}
              onRowsPerPageChange={handleOnRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  )
}

OrganizationsTable.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      directory: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
}

OrganizationsTable.defaultProps = {
  page: 0,
  rowsPerPage: 50,
  rowsPerPageOptions: [25, 50, 75, 100],
}

export default OrganizationsTable
