import React, { useMemo } from 'react'
import { Card, fireToast } from '@sympaoy/pomegranate'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import IAMCard from '../components/organization/IAMCard'
import { GET_ORGANIZATION_BY_ID } from '../api/queries'
import { ENABLE_NEW_ROLES, UPDATE_SSO_CONFIGURATION } from '../api/mutations'
import { OrganizationSettingsCard } from '../components/organization/OrganizationSettingsCard'

const Container = styled.div`
  display: flex;
  gap: 24px;
`

export const Organization = () => {
  const { id } = useParams()
  const organizationId = useMemo(() => parseInt(id, 10), [id])

  const {
    data,
    loading,
    refetch: reloadData,
  } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: {
      organizationId,
    },
  })

  const [saveB2CConfiguration] = useMutation(UPDATE_SSO_CONFIGURATION, {
    onError: () => {
      fireToast('Something went wrong', 'error')
    },
    onCompleted: () => {
      fireToast('Configuration saved', 'success')
    },
  })

  const [updateNewRolesEnabled] = useMutation(ENABLE_NEW_ROLES, {
    onError: () => {
      fireToast('Something went wrong', 'error')
    },
    onCompleted: () => {
      fireToast('Configuration saved', 'success')
    },
  })

  const saveAzureB2CConfiguration = async (callbackData) => {
    await saveB2CConfiguration({
      variables: {
        organizationId,
        ...callbackData,
      },
    })
    await reloadData()
  }

  const onNewRolesEnabled = async (state, addUsersToDefaultRole) => {
    if (!state) {
      fireToast('Cannot disable active new roles feature', 'error')
      return
    }
    await updateNewRolesEnabled({
      variables: {
        enabled: state,
        organizationId,
        addUsersToDefaultRole,
      },
    })
    await reloadData()
  }

  return (
    !loading &&
    data && (
      <Container>
        <Card title="Info" qaId="organization__info-card">
          ID: {data.organization.id}
          <br />
          Code: {data.organization.directory}
          <br />
          Name: {data.organization.name}
        </Card>
        <IAMCard
          qaIdPrefix="organization"
          configuration={data.organization.sso}
          onSave={saveAzureB2CConfiguration}
        />
        {data.organization.sso?.enabled && (
          <OrganizationSettingsCard
            qaIdPrefix="organization"
            organizationData={data.organization}
            onNewRolesEnabled={onNewRolesEnabled}
          />
        )}
      </Container>
    )
  )
}
