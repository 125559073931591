import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Logo, PrimaryButton } from '@sympaoy/pomegranate'
import { useMsal } from '@azure/msal-react'
import scopes from '../auth/scopes'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #e5e5e5;
`

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

const Frame = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 24px;
  border-radius: 10px;

  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 4px 0 #0000001a;
`

const Footer = styled.footer`
  margin-inline: auto;
  padding: 20px;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
`

const SignIn = () => {
  const { instance } = useMsal()

  const handleLogin = useCallback(async () => {
    await instance.loginRedirect({
      scopes,
      extraQueryParameters: {
        organization: '!',
        organizationId: 1,
      },
    })
  }, [instance])

  return (
    <Page>
      <Container>
        <Frame>
          <Logo margin="0 0 40px 0" size="l" qaId="login__logo" />
          <PrimaryButton onPress={handleLogin} qaId="login__button">
            Log In
          </PrimaryButton>
        </Frame>
      </Container>
      <Footer>© 2023 Sympa</Footer>
    </Page>
  )
}

export default SignIn
