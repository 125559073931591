import { PublicClientApplication } from '@azure/msal-browser'
import { BrowserCacheLocation } from '@azure/msal-browser/dist/utils/BrowserConstants'
import authSettings from './authSettings'

export default new PublicClientApplication({
  auth: authSettings,
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
  system: {
    loggerOptions: {
      logLevel: 0,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        console.debug(level, message)
      },
      piiLoggingEnabled: false,
    },
  },
})
