/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const UPDATE_SSO_CONFIGURATION = gql`
  mutation ssoConfiguration(
    $organizationId: Int!
    $enabled: Boolean!
    $technicalProfileReferenceId: String!
  ) {
    configureSso(
      input: {
        organizationId: $organizationId
        enabled: $enabled
        technicalProfileReferenceId: $technicalProfileReferenceId
      }
    ) {
      organization {
        sso {
          enabled
          technicalProfileReferenceId
        }
      }
    }
  }
`

export const ENABLE_NEW_ROLES = gql`
  mutation newRolesEnablement(
    $organizationId: Int!
    $enabled: Boolean!
    $addUsersToDefaultRole: Boolean!
  ) {
    enableNewRoles(
      input: {
        enabled: $enabled
        organizationId: $organizationId
        addUsersToDefaultRole: $addUsersToDefaultRole
      }
    ) {
      organization {
        organizationSettings {
          isNewRolesEnabled
        }
      }
    }
  }
`
